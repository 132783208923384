import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

//COMPONENTS
import { SelectPathComponent } from './select-path/select-path.component';
import { SelectEventComponent } from '../select-event/select-event.component';
import { MyEventsComponent } from './my-events/my-events.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { SelectFriendsComponent } from '../select-friends/select-friends.component';
import { SelectSeatComponent } from '../select-seat/select-seat.component';
import { CheckoutComponent } from '../checkout/checkout.component';
import { SummaryComponent } from '../summary/summary.component';

const routes: Routes = [
  {
    path: '',
    component: SelectPathComponent,
    title: 'Exchange | Select Path'
  },
  {
    path: 'select-event',
    component: SelectEventComponent,
    title: 'Exchange | Select Event',
    data: {
      title: 'Ticket Exchange',
      type:  'exchange'
    }
  },
  {
    path: 'select-friends',
    component: SelectFriendsComponent,
    title: 'Exchange | Select Friend'
  },
  {
    path: 'select-seat',
    component: SelectSeatComponent,
    title: 'Exchange | Select Seat',
    data: {
      type: 'exchange'
    }
  },
  {
    path: 'my-events',
    component: MyEventsComponent,
    title: 'Exchange | My Events',
  },
  {
    path: 'my-tickets',
    component: MyTicketsComponent,
    title: 'Exchange | My Tickets',
  },
  {
    path: 'checkout',
    component: CheckoutComponent,
    title: 'Exchange | Checkout'
  },
  {
    path: 'summary/:status',
    component: SummaryComponent,
    title: 'Exchange | Summary'
  }
]

@NgModule({
 imports:  [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ExchangeRoutingModule { }

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { Inventory } from 'src/app/shared/models/inventory.model';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(
    private http: HttpClient
  ){}

  private endpoint: string  = '/ticket_exchange/customer/inventory/';

  /**
   * Instanciamos la WriteableSignal inventory, que almacenará el inventario del usuario.
   * Para prevenir modificaciones directas, exponemos un getter getInventory que devuelve el valor de inventory más actualizado. 
   * El setter es la función fetchInventory, que recibe un tipo de usuario y hace una petición al endpoint. 
   * @type {WritableSignal<Inventory[] | undefined>} inventory
   * @type {Signal<Inventory[] | undefined>} getInventory
   */
  private inventory:            WritableSignal<Inventory[] | undefined> = signal(undefined);
  public readonly getInventory: Signal<Inventory[] | undefined>         = computed(() => this.inventory());

  /**
   * Método para obtener el inventario del usuario.
   * Este método necesita el parámetro type para saber que parámetros crear en la petición.
   * Posteriormente, si es exitosa, seteamos el valor de inventory con el inventario obtenido y si no 
   * mostramos un error en consola. // To Do Throw Error
   * @param {string} type 
   * @returns void
   */
  public fetchInventory(type: 'Men'|'Women' = 'Men'): void {
    
    const params: HttpParams = type === 'Women' ?
       new HttpParams({fromObject: {flow: 'women_exchange'}}) : 
       new HttpParams({});

    this.http.get<Inventory[]>(this.endpoint, { params: params }).subscribe({
      next:  inventory => this.inventory.set(inventory),
      error: error => console.error(error)
    });

  }

}

<div class="h-full">
    
    <!-- MAIN -->
    <section class="h-1/2 lg:h-1/3">

        <!-- TITLE -->
        <div class="flex justify-center items-center h-4/6">
            <h2 class="text-3xl lg:text-4xl text-darkgray font-semibold">
                {{templateVars.title}}
            </h2>
        </div>

        <!-- BUTTONS & MESSAGE -->
        <div class="bg-white h-2/6">

          <div class="flex items-center h-full justify-around lg:max-w-4xl lg:mx-auto" *ngIf="hasMembership else message">
            <button class="button w-1/3" routerLink="/exchange/select-event" *ngIf="!isWomen">
                Buy Tickets
            </button>

            <button class="button w-1/3" (click)="goToListTickets()">
                List Tickets
            </button>
          </div>

          <ng-template #message>
            
            <div class="container px-5 sm:mx-auto flex items-center h-full max-w-4xl">
                <p class="text-xs sm:text-sm md:text-base text-darkgray">
                    {{templateVars.error}}
                </p>
            </div>

          </ng-template>

        </div>

    </section>

    <!-- IMAGE -->
    <section class="h-1/2 lg:h-2/3">
        <div class="h-full">
            <img [src]="templateVars.image" alt="Exchange Background" class="h-full object-cover brightness-[0.3] w-full">
        </div>
    </section>

</div>
import { Component, OnInit, Signal, inject } from '@angular/core';
import { Router } from '@angular/router';
import { InventoryService } from 'src/app/core/services/inventory.service';
import { TraitService } from 'src/app/core/services/trait.service';
import { Inventory } from 'src/app/shared/models/inventory.model';
import { Trait } from 'src/app/shared/models/exchange/trait.model';
import { TicketService } from 'src/app/core/services/ticket.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-events',
  templateUrl: './my-events.component.html',
  styleUrl: './my-events.component.css'
})
export class MyEventsComponent implements OnInit {

  constructor(
    private location:  Location,
    private router:    Router,
    private trait:     TraitService,
    private ticket:    TicketService,
    private inventory: InventoryService,
    ){
      // Instanciamos el estado de la ruta
      const state: {type: 'Men'|'Women'} = this.location.getState()! as {type: 'Men'|'Women'};
      
      // Comprobamos si el estado incluye el tipo de usuario, si no, redirigimos a la página inicial
      if(state.type !== 'Men' && state.type !== 'Women'){
        this.router.navigate(['/exchange']);
        return;
      }

      // Seteamos el tipo de usuario y obtenemos su inventario
      this.type = state.type;
      this.inventory.fetchInventory(this.type);  
  }

  /**
   * Tipo de usuario, necesario para obtener el inventario. 
   */  
  private   type!:          'Men' | 'Women';

  /**
   * Datos del Trait del usuario.
   */
  protected userTraitData!: Trait;
  protected userVerified!:  boolean;
  
  /**
   * Inventario del usuario.
   */
  protected userInventory:  Signal<Inventory[]|undefined> = this.inventory.getInventory;

  /**
   * Variables de template.
   * 
   * 1. header:     Título de la página.
   * 2. subtitle:   Subtítulo de la página.
   * 3. header2:    Título de la sección de eventos.
   * 4. subtitle2:  Subtítulo de la sección de eventos.
   * 5. noInventory: Mensaje de error cuando el usuario no tiene tickets en su inventario.
   */
  public    templateVars:   {header: string, subtitle: string, header2: string, subtitle2: string, noInventory: string } = {
    header:     'Ticket Exchange',
    subtitle:   'My Bank Details',
    header2:    'My Events',
    subtitle2:  'Please click on the View Tickets option to access your tickets. From here you can List/Delist your ticket',
    noInventory: `You have no tickets in your inventory.`
  }

  ngOnInit(): void {
   this.initComponent();
  }
  
  /**
   * Método que setea el estado de verificación del usuario en base a la respuesta
   * del Output del BankAccountComponent.
   * @param status Estado de verificación
   */
  protected setVerificationStatus(status: boolean): void {  
    this.userVerified = status;
  }

  /**
   * Método que redirige al usuario a la página de MyTickets.
   * @param id Id del evento
   */
  protected goToTicket(id: number){

    const eventId: string = id.toString(),
          route:   string = this.type === 'Men' ?
            '/exchange/my-tickets' :
            '/women-texchange/my-tickets';

    this.ticket.setEventId(eventId);
    this.router.navigate([route], { queryParams: { event: eventId } })
  }

  /**
   * Inicializa el componente.
   * Obtiene los datos del Trait del usuario y setea el estado de verificación.
   */
  private initComponent(): void {
    this.trait.getTraitData().subscribe({
      error:  (error: Error) => console.error(error),
      next:   (data: Trait)  => {
        this.userTraitData = data;
        this.setVerificationStatus(data.traitData.booleanData);
      }
    });
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

//COMPONENTS
import { ExchangeComponent } from './exchange.component';
import { SelectPathComponent } from './select-path/select-path.component';

//MODULES
import { SelectEventModule } from '../select-event/select-event.module';
import { FooterModule } from 'src/app/shared/components/globals/footer/footer.module';
import { LoaderModule } from 'src/app/shared/components/globals/loader/loader.module';

// ROUTES
import { ExchangeRoutingModule } from './exchange-routing.module';

// SERVICES
import { AvailabilityService } from 'src/app/core/services/availability.service';
import { ExchangeService } from 'src/app/core/services/override/availability/exchange.service';
import { BackButtonModule } from 'src/app/shared/components/globals/back-button/back-button.module';
import { MyEventsComponent } from './my-events/my-events.component';
import { BankAccountComponent } from './bank-account/bank-account.component';
import { FormsModule } from '@angular/forms';
import { InventoryItemComponent } from './my-events/inventory-item/inventory-item.component';
import { MyTicketsComponent } from './my-tickets/my-tickets.component';
import { SelectFriendsModule } from '../select-friends/select-friends.module';
import { SelectSeatModule } from '../select-seat/select-seat.module';
import { CheckoutService } from 'src/app/core/services/checkout.service';
import { ExchangeTransactionService } from 'src/app/core/services/override/checkout/exchange-transaction.service';
import { CheckoutModule } from '../checkout/checkout.module';
import { SummaryModule } from '../summary/summary.module';


@NgModule({
  declarations: [
    ExchangeComponent,
    SelectPathComponent,
    MyEventsComponent,
    BankAccountComponent,
    InventoryItemComponent,
    MyTicketsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    SelectEventModule,
    SelectFriendsModule,
    SelectSeatModule,
    SummaryModule,
    CheckoutModule,
    FooterModule,
    LoaderModule,
    ExchangeRoutingModule,
    BackButtonModule
  ],
  providers: [
    {
      provide:  AvailabilityService, 
      useClass: ExchangeService
    },
    {
      provide:  CheckoutService, 
      useClass: ExchangeTransactionService
    }
  ]
})
export class ExchangeModule {}

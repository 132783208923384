import { Component, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/core/services/auth.service';
import { Configuration } from 'src/app/shared/models/configuration.model';
import { User } from 'src/app/shared/models/user.model';
import { APP_CONFIG } from 'src/configuration/configuration';

@Component({
  selector: 'app-select-path',
  templateUrl: './select-path.component.html',
  styleUrl: './select-path.component.css'
})
export class SelectPathComponent {

  private   auth:   AuthService     = inject(AuthService);
  private   config: Configuration   = inject(APP_CONFIG);
  private   type:   'Men' | 'Women' = inject(ActivatedRoute).snapshot.data['type'];
  private   router: Router          = inject(Router);

  protected client:         string = this.config.clientName;
  protected membershipId:   number = this.type === 'Men' ? parseInt(this.config.exchange?.men_id!) : parseInt(this.config.exchange?.women_id!);
  protected hasMembership!: boolean;

  public templateVars: {title: string, error: string, image: string} = {
    title: this.type === 'Men' ? 'Ticket Exchange' : 'Women’s Ticket Exchange',
    error: `The ${this.client} ticket exchange is only available to ${this.type}’s Season Ticket holders and current season Members. 
            If you have recently purchased a membership this can take up to 48 hours to become active.`,
    image: this.config.background
  };

  public get isWomen(): boolean {
    return this.type === 'Women';
  }

  ngOnInit(): void {
    this.initComponent();
  }

  public goToListTickets(): void {
    this.type === 'Men' ?
      this.router.navigate(['/exchange/my-events'], {state: {type: this.type}}) :
      this.router.navigate(['/women-texchange/my-events'], {state: {type: this.type}});
  }

  /**
   * Init Component
   * Obtenemos el current user y verificamos si tiene membresia y el id correspondiente
   */
  private initComponent(): void {
    this.auth.getUser().then(user$ => {
      
      // Casteamos user to User
      const user = user$ as User;

      // Verificamos si tiene membresia y el id correspondiente
      if(user.tdc_info.memberships.length){
        
        user.tdc_info.memberships.forEach(membership => {
          if(membership.membership_level.id === this.membershipId){
            this.hasMembership = true;
          }
        });

      }else{
        this.hasMembership = false;
      }
      
    }); 
  }

}

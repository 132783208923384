import { Component, OnDestroy, Signal, ViewChild, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from 'src/app/core/services/modal.service';
import { TicketService } from 'src/app/core/services/ticket.service';
import { Ticket, TicketData } from 'src/app/shared/models/exchange/ticket.model';

@Component({
  selector: 'app-my-tickets',
  templateUrl: './my-tickets.component.html',
  styleUrl: './my-tickets.component.css'
})
export class MyTicketsComponent {
  
  private ticketService: TicketService = inject(TicketService);
  private modalService:  ModalService  = inject(ModalService);
  
  protected ticket:      Signal<Ticket | undefined> = this.ticketService.tickets;
  
  
  /**
   * Getter que obtiene el valor de la propiedad isRefreshing del servicio TicketService.
   */
  protected get isRefreshing(): boolean {
    return this.ticketService.isRefreshing;
  }

  /**
   * Método helper para eliminar los parentesis de un string.
   * @param text 
   * @returns {string} string 
   */
  public removeParentesis(text: string): string {
    return text.replace(/\(.*?\)/g, '');
  }

  /**
   * Método para listar un ticket (poner en venta).
   * Lanza un modal de confirmación, y si el usuario confirma, se llama al método createConsignBack del servicio.
   * @param ticket
   * @returns void
   */
  protected releaseTicket(ticket: TicketData): void {
    
    const title:   string = "Continue?",
          message: string = `<p>Are you sure you want to release this ticket?</p>`,
          eventId: number = parseInt(this.ticketService.eventId()!);
     
    this.modalService.createConfirmationModal(title,message).subscribe(response => !response.isConfirmed ? null :
      this.ticketService.createConsignBack(eventId,ticket).subscribe({
        next: () => this.ticketService.refreshTickets(),
        error: error => console.error(error)
      })
    )

  }

  /**
   * 
   * @param marketOfferId 
   * @param buyerTypeId 
   */
  protected recoverTicket(marketOfferId: TicketData['market_offer']['id'], buyerTypeId: TicketData['buyer_type']['id']): void {
    
    const title:   string = "Continue?",
          message: string = '<p>Are you sure you wish to delist your ticket?</p>';

    this.modalService.createConfirmationModal(title,message).subscribe(response => !response.isConfirmed ? null : 
      this.ticketService.deleteConsignBack(marketOfferId,buyerTypeId).subscribe({
        next: () => this.ticketService.refreshTickets(),
        error: error => console.error(error)
      })
    )

  }
}
